import { DefaultSeo } from 'next-seo';
import { AppProps } from 'next/app';
import { Manrope } from 'next/font/google';
import Head from 'next/head';
import { useRouter } from 'next/router';
import NextTopLoader from 'nextjs-toploader';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { Scripts } from '@/components/scripts';
import { ModalContainer } from '@/components/ui/modals/ModalContainer';

import { _IS_SEO_DISABLED_ } from '@/helpers/condition/is-seo-disabled';

import { useMetrikaPageHits } from '@/hooks/useMetrikaPageHits';

import { persistor, store } from '@/store/store';

import { SEO_CONSTANTS, SEO_DEFAULT } from '@/constants/seo.constant';

import 'styles/main.scss';
import 'swiper/scss';

import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';

const manrope = Manrope({
  weight: ['400', '500', '600', '700'],
  subsets: ['cyrillic-ext'],
  variable: '--font-manrope',
  display: 'swap',
  style: 'normal',
  preload: true,
});

function App({ Component, pageProps: { ...pageProps } }: AppProps) {
  const router = useRouter();

  useMetrikaPageHits();

  const tree = (
    <div className={`${manrope.variable}`}>
      <Component {...pageProps} />
      <ModalContainer />
      <div id="myportal" />
    </div>
  );

  return (
    <>
      <Provider store={store}>
        <NextTopLoader color="#ff283d" showSpinner={false} />

        <DefaultSeo
          dangerouslySetAllPagesToNoIndex={_IS_SEO_DISABLED_}
          dangerouslySetAllPagesToNoFollow={_IS_SEO_DISABLED_}
          canonical={SEO_CONSTANTS.website + router.asPath.split('?')[0]}
          {...SEO_DEFAULT(SEO_CONSTANTS.website + router.asPath)}
        />
        <PersistGate persistor={persistor} loading={tree}>
          <Head>
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5" />
          </Head>
          {tree}
          <Scripts />
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
